<template>
    <div>
        <Table
            ref="table"
            :isLoading="isLoading"
            :columnConfig="tableColumnConfig"
            :rows="tableShowData"
            :isPagination="true"
            :isFocusRow="true"
            :excelConfig="excelConfig"
            :toolbox="['columnSetting', 'excel', 'zooming']"
            :newToolKeys="newToolKeys"
            v-model:sortDefault="sortValue"
            :isFixedHeader="true"
        >
            <template v-slot:insDetailLink="{ isHeader, row }">
                <router-link
                    v-if="!isHeader"
                    :to="{
                        name: 'TiaInsuranceDetail',
                        params: {
                            manCode: row.mainCode,
                            id: this.filterLog.manCodes?.value[0],
                            source: 2
                        }
                    }"
                    :class="{ 'detail-link-icon': !$isMobile() }"
                >
                    <Button
                        v-if="$isMobile()"
                        buttonSize="small"
                        iconClass="detail-link-icon"
                        buttonWord="明細"
                        class="mobile-button"
                    />
                </router-link>
            </template>
            <template v-slot:payer="{ isHeader, row }">
                <template v-if="!isHeader">
                    <a
                        class="customer"
                        :class="{ 'no-data': row.payer === '-' }"
                        @click.stop="openCustomer(row.payerCode, row.payer)"
                    >
                        {{ row.payer ?? '-' }}
                    </a>
                </template>
            </template>
            <template v-slot:insured="{ isHeader, row }">
                <template v-if="!isHeader">
                    <a
                        class="customer"
                        :class="{ 'no-data': row.insured === '-' }"
                        @click.stop="openCustomer(row.insuredCode, row.insured)"
                    >
                        {{ row.insured ?? '-' }}
                    </a>
                </template>
            </template>
        </Table>
    </div>
    <div v-if="isShowReminderSettingModal">
        <InsuranceReminderSettingModal
            v-model="isShowReminderSettingModal"
            v-bind="$attrs"
        />
    </div>

    <InsuranceExcelEditingModal
        :dataList="listData"
        v-model="isShowExcelEditingModal"
        v-bind="$attrs"
    />
</template>

<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import {
    insuranceColumns,
    pickInsurance2Columns
} from '@/assets/javascripts/tia/setting'
import InsuranceReminderSettingModal from '@/containers/tia/InsuranceReminderSettingModal.vue'
import InsuranceExcelEditingModal from '@/containers/tia/InsuranceExcelEditingModal.vue'
import Button from '@/components/Button.vue'

export default {
    name: 'insuranceTable',
    components: {
        Table,
        InsuranceReminderSettingModal,
        InsuranceExcelEditingModal,
        Button
    },
    props: {
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        isLoading: {
            type: Boolean,
            default: true
        },
        eventList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        filterLog: {
            type: Object,
            default: function () {
                return {}
            }
        },
        payload: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    methods: {
        getNoticeCount: function (row) {
            const checkedCount = this.eventList[row.mainCode] || 0 // 勾完通知的暫存'
            const detailCheckCount =
                this.$store.state.detailCheckedCount[row.mainCode] ?? 0
            const unreadCount =
                row.hasServiceEvent - checkedCount - detailCheckCount
            return unreadCount
        },
        openCustomer: function (code, name) {
            if (name === '-') return
            this.$setGaEvent('goSearchCustomer', 'click-Link')

            const searchPayload = { ...this.payload }
            searchPayload.customerCode = code
            searchPayload.customerName = name
            /* 刪除客戶查詢 payload 不需要的部分 */
            delete searchPayload.receiveDateStart
            delete searchPayload.receiveDateEnd
            this.$crossPageFilter('TiaCustomer', searchPayload)
        },
        hasSupplierData: function (row) {
            return row.fromSupplierDataStatus !== null
        },
        transformData: function (rows) {
            return rows.map((row) => {
                if (row.jobTypeText === '壽險') row['jobCode'] = 'life'
                else if (row.jobTypeText === '產險') row['jobCode'] = 'property'
                else if (row.jobTypeText === '團險及旅平險') {
                    row['jobCode'] = 'group'
                    row.jobTypeText = '團/旅平險'
                }

                row.rfyp = {
                    value: row?.rfyp || '-',
                    unit: row?.rfyp ? row?.rfypUnit || '元' : ''
                }

                // for excel setting
                row.payer = row.payer || '-'
                row.insured = row.insured || '-'

                return row
            })
        },
        showToolsModal: function (toolKey) {
            if (this.isLoading) return
            if (toolKey === 'reminder') {
                this.isShowReminderSettingModal = true
            }
            if (toolKey === 'excelEditing') {
                this.isShowExcelEditingModal = true
            }

            this.$setGaEvent(`${toolKey}ToolOpen`, 'click-Button')
        }
    },
    computed: {
        tableShowData: function () {
            if (this.listData.length === 0) return []
            let dataset = _.cloneDeep(this.listData)
            dataset = this.transformData(dataset)
            return dataset
        },
        fileName: function () {
            const Today = new Date()
            const year = Today.getFullYear()
            const month = Today.getMonth() + 1
            const day = Today.getDate()
            const account = '_' + this.filterLog?.manCodes?.label

            return `${year}${month > 9 ? month : '0' + month}${
                day > 9 ? day : '0' + day
            }${account}_保單清單(客戶上傳保單)`
        },
        excelConfig: function () {
            const filters = _.pick(this.filterLog, ['titleType', 'manCodes'])
            return {
                fileName: this.fileName,
                filters
            }
        },
        newToolKeys: function () {
            const keys = []
            // 2022-10-20:開放Admin可以使用客服服務後需依request的manCode是否和登入帳號相符去做限制
            if (this.$hasPermission('downloadExcel')) keys.push('excelEditing')
            keys.push('reminder')
            return keys
        },
        tableColumnConfig() {
            return _.pick(insuranceColumns, pickInsurance2Columns)
        }
    },
    watch: {
        tableShowData: {
            handler: function () {
                this.$reloadTips()
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            isShowDueDateTips: false,
            isShowReminderSettingModal: false,
            isShowExcelEditingModal: false,
            sortValue: { sort: 'desc', sortBy: 'effectiveDate' }
        }
    }
}
</script>

<style lang="scss" scoped>
:deep(.ins-detail-link) {
    position: relative;
    > .detail-link-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}

.customer {
    display: flex;
    text-decoration: underline;
    color: $primary-blue;
    cursor: pointer;
    &.no-data {
        color: $primary-black;
        cursor: default;
        text-decoration: none;
    }
}

.table-wrapper {
    :deep(.header) {
        .info-icon {
            margin-left: 3px;
            width: 14px;
            height: 14px;
        }
    }
    @media screen and (max-width: 576px) {
        :deep(.card .row-container .row.body) {
            margin-right: auto;

            .ins-detail-link {
                &.cell {
                    max-width: fit-content;
                    justify-content: flex-end;
                    &::before {
                        display: none;
                    }
                }
            }

            .supplier {
                &.cell {
                    padding-top: 10px;
                    border-top: 1px dashed $fourth-grey;
                }
            }
        }
        .mobile-button {
            margin-left: 5px;
            background-color: transparent;
            box-shadow: 0 0 0 1px $primary-black inset;
        }
        :deep(.small-button) {
            .button-icon {
                height: 20px;
                width: 20px;
                margin-right: 3px;
            }
        }
    }
}
//手機版卡片隱藏表格全螢幕功能
:deep(.tool-option) {
    &.full-size {
        display: flex;
        @media screen and (max-width: 576px) {
            display: none !important;
        }
    }
}

:deep(p) {
    display: flex;
    color: $secondary-grey;
    font-size: 12px;
    margin: 0;
    padding: 0;
    span {
        display: inline-block;
    }
}

:deep(.table-container) {
    @media screen and (max-width: 576px) {
        &.card {
            .table {
                .mobile-header {
                    display: block;
                }
            }
        }
    }
}
</style>
